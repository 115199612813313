import * as WebAuthnJSON from "@github/webauthn-json/browser-ponyfill"

function getCSRFToken() {
  const CSRFSelector = document.querySelector('meta[name="csrf-token"]')
  if (CSRFSelector) {
    return CSRFSelector.getAttribute("content")
  } else {
    return null
  }
}

function callback(name, body) {
  const callback_url = "/passkeys/callback"

  fetch(encodeURI(callback_url), {
    method: "POST",
    body: JSON.stringify({ body, name }),
    headers: {
      "Content-Type": "application/json",
      "Accept": "application/json",
      "X-CSRF-Token": getCSRFToken()
    },
  }).then(function (response) {
    window.location.reload()
  })
}

function create(nickname, data) {
  const options = WebAuthnJSON.parseCreationOptionsFromJSON({ "publicKey": data })
  WebAuthnJSON.create(options).then((credentials) => {
    callback(nickname, credentials)
  }).catch(function (error) {
    if (error.name === "InvalidStateError") {
      const errorElement = document.querySelector(".invalid-state-error")
      errorElement.style.display = 'flex'
    }
  })

  console.log("credential: Creating new public key credential...")
}

export { create }
