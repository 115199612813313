import "playbook-ui/dist/playbook-rails.js";
import "@fortawesome/fontawesome-pro/js/fontawesome";
import "@fortawesome/fontawesome-pro/js/regular";
import "@hotwired/turbo-rails";
import "../controllers/application"


import { Turbo } from "@hotwired/turbo-rails";
Turbo.session.drive = false

// import { Turbo } from "@hotwired/turbo-rails";
// Turbo.session.drive = true; -- this turns on the Turbo Drive feature on a specific page
