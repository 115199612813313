import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  doNotShowAgainUpdate(event) {
    const action = "/passkey_prompts/update_flag"
    const options = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Accept": "application/json",
        "X-CSRF-Token": getCSRFToken()
      },
      body: JSON.stringify({ checked: event.target.checked }),
    }

    fetch(action, options)

    function getCSRFToken() {
      const CSRFSelector = document.querySelector('meta[name="csrf-token"]')
      if (CSRFSelector) {
        return CSRFSelector.getAttribute("content")
      } else {
        return null
      }
    }
  }
}
