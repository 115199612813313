import { Controller } from "@hotwired/stimulus"
import * as Credential from "../credential"

export default class extends Controller {

  showModal(event) {
    document.getElementById("passkey-dialog").showModal()
  }

  showEditModal(event) {
    document.getElementById("edit-passkey-dialog").showModal()
  }

  submit(event) {
    event.preventDefault()

    newRegistration(event.target)

    function ok(nickname, response) {
      response.json().then((data) => {
        if (data.user) {
          Credential.create(nickname, data)
        }
      })
    }

    function newRegistration(eventTarget) {
      const formData = new FormData(eventTarget)
      const nickname = formData.get("registration[name]")

      const headers = new Headers()
      const action = eventTarget.action
      const options = {
        method: eventTarget.method,
        headers: headers,
        body: formData
      }

      fetch(action, options).then((response) => {
        if (response.ok) {
          ok(nickname, response)
        } else {
          err(response)
        }
      })
    }

    function err(response) {
      console.log("new-registration Error", response)
    }
  }
}
